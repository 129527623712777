/*$super_light_grey: #efefef;
$light_grey: #e6e7e9;
$light_grey2: #d3d3d3;

$grey: #9a9a9a;
$grey2: #454e57;
$grey3: #363e49;
$dark_grey: #757c85;*/
.clearfix {
  *zoom: 1; }
  .clearfix:before, .clearfix:after {
    content: " ";
    display: table; }
  .clearfix:after {
    clear: both; }

/*@include keyframes(vertical-rotation) {
	0%   {  @include rotate(0); }
	100% { @include rotate(180); }
}*/
@-webkit-keyframes Floating {
  0% {
    -webkit-transform: translate(0, 0px);
    -khtml-transform: translate(0, 0px);
    -moz-transform: translate(0, 0px);
    -ms-transform: translate(0, 0px);
    -o-transform: translate(0, 0px);
    transform: translate(0, 0px); }
  50% {
    -webkit-transform: translate(0, 15px);
    -khtml-transform: translate(0, 15px);
    -moz-transform: translate(0, 15px);
    -ms-transform: translate(0, 15px);
    -o-transform: translate(0, 15px);
    transform: translate(0, 15px); }
  100% {
    -webkit-transform: translate(0, 0px);
    -khtml-transform: translate(0, 0px);
    -moz-transform: translate(0, 0px);
    -ms-transform: translate(0, 0px);
    -o-transform: translate(0, 0px);
    transform: translate(0, 0px); } }

@-moz-keyframes Floating {
  0% {
    -webkit-transform: translate(0, 0px);
    -khtml-transform: translate(0, 0px);
    -moz-transform: translate(0, 0px);
    -ms-transform: translate(0, 0px);
    -o-transform: translate(0, 0px);
    transform: translate(0, 0px); }
  50% {
    -webkit-transform: translate(0, 15px);
    -khtml-transform: translate(0, 15px);
    -moz-transform: translate(0, 15px);
    -ms-transform: translate(0, 15px);
    -o-transform: translate(0, 15px);
    transform: translate(0, 15px); }
  100% {
    -webkit-transform: translate(0, 0px);
    -khtml-transform: translate(0, 0px);
    -moz-transform: translate(0, 0px);
    -ms-transform: translate(0, 0px);
    -o-transform: translate(0, 0px);
    transform: translate(0, 0px); } }

@-ms-keyframes Floating {
  0% {
    -webkit-transform: translate(0, 0px);
    -khtml-transform: translate(0, 0px);
    -moz-transform: translate(0, 0px);
    -ms-transform: translate(0, 0px);
    -o-transform: translate(0, 0px);
    transform: translate(0, 0px); }
  50% {
    -webkit-transform: translate(0, 15px);
    -khtml-transform: translate(0, 15px);
    -moz-transform: translate(0, 15px);
    -ms-transform: translate(0, 15px);
    -o-transform: translate(0, 15px);
    transform: translate(0, 15px); }
  100% {
    -webkit-transform: translate(0, 0px);
    -khtml-transform: translate(0, 0px);
    -moz-transform: translate(0, 0px);
    -ms-transform: translate(0, 0px);
    -o-transform: translate(0, 0px);
    transform: translate(0, 0px); } }

@-o-keyframes Floating {
  0% {
    -webkit-transform: translate(0, 0px);
    -khtml-transform: translate(0, 0px);
    -moz-transform: translate(0, 0px);
    -ms-transform: translate(0, 0px);
    -o-transform: translate(0, 0px);
    transform: translate(0, 0px); }
  50% {
    -webkit-transform: translate(0, 15px);
    -khtml-transform: translate(0, 15px);
    -moz-transform: translate(0, 15px);
    -ms-transform: translate(0, 15px);
    -o-transform: translate(0, 15px);
    transform: translate(0, 15px); }
  100% {
    -webkit-transform: translate(0, 0px);
    -khtml-transform: translate(0, 0px);
    -moz-transform: translate(0, 0px);
    -ms-transform: translate(0, 0px);
    -o-transform: translate(0, 0px);
    transform: translate(0, 0px); } }

@keyframes Floating {
  0% {
    -webkit-transform: translate(0, 0px);
    -khtml-transform: translate(0, 0px);
    -moz-transform: translate(0, 0px);
    -ms-transform: translate(0, 0px);
    -o-transform: translate(0, 0px);
    transform: translate(0, 0px); }
  50% {
    -webkit-transform: translate(0, 15px);
    -khtml-transform: translate(0, 15px);
    -moz-transform: translate(0, 15px);
    -ms-transform: translate(0, 15px);
    -o-transform: translate(0, 15px);
    transform: translate(0, 15px); }
  100% {
    -webkit-transform: translate(0, 0px);
    -khtml-transform: translate(0, 0px);
    -moz-transform: translate(0, 0px);
    -ms-transform: translate(0, 0px);
    -o-transform: translate(0, 0px);
    transform: translate(0, 0px); } }

@-webkit-keyframes bounce {
  16.65% {
    color: #006b24;
    -webkit-transform: translateY(3px);
    -khtml-transform: translateY(3px);
    -moz-transform: translateY(3px);
    -ms-transform: translateY(3px);
    -o-transform: translateY(3px);
    transform: translateY(3px); }
  33.3% {
    -webkit-transform: translateY(-2px);
    -khtml-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
    transform: translateY(-2px); }
  49.95% {
    color: #006d9d;
    -webkit-transform: translateY(1px);
    -khtml-transform: translateY(1px);
    -moz-transform: translateY(1px);
    -ms-transform: translateY(1px);
    -o-transform: translateY(1px);
    transform: translateY(1px); }
  66.6% {
    color: #006b24;
    -webkit-transform: translateY(0px);
    -khtml-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px); }
  83.25% {
    -webkit-transform: translateY(0px);
    -khtml-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px); }
  100% {
    -webkit-transform: translateY(0);
    -khtml-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-moz-keyframes bounce {
  16.65% {
    color: #006b24;
    -webkit-transform: translateY(3px);
    -khtml-transform: translateY(3px);
    -moz-transform: translateY(3px);
    -ms-transform: translateY(3px);
    -o-transform: translateY(3px);
    transform: translateY(3px); }
  33.3% {
    -webkit-transform: translateY(-2px);
    -khtml-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
    transform: translateY(-2px); }
  49.95% {
    color: #006d9d;
    -webkit-transform: translateY(1px);
    -khtml-transform: translateY(1px);
    -moz-transform: translateY(1px);
    -ms-transform: translateY(1px);
    -o-transform: translateY(1px);
    transform: translateY(1px); }
  66.6% {
    color: #006b24;
    -webkit-transform: translateY(0px);
    -khtml-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px); }
  83.25% {
    -webkit-transform: translateY(0px);
    -khtml-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px); }
  100% {
    -webkit-transform: translateY(0);
    -khtml-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-ms-keyframes bounce {
  16.65% {
    color: #006b24;
    -webkit-transform: translateY(3px);
    -khtml-transform: translateY(3px);
    -moz-transform: translateY(3px);
    -ms-transform: translateY(3px);
    -o-transform: translateY(3px);
    transform: translateY(3px); }
  33.3% {
    -webkit-transform: translateY(-2px);
    -khtml-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
    transform: translateY(-2px); }
  49.95% {
    color: #006d9d;
    -webkit-transform: translateY(1px);
    -khtml-transform: translateY(1px);
    -moz-transform: translateY(1px);
    -ms-transform: translateY(1px);
    -o-transform: translateY(1px);
    transform: translateY(1px); }
  66.6% {
    color: #006b24;
    -webkit-transform: translateY(0px);
    -khtml-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px); }
  83.25% {
    -webkit-transform: translateY(0px);
    -khtml-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px); }
  100% {
    -webkit-transform: translateY(0);
    -khtml-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-o-keyframes bounce {
  16.65% {
    color: #006b24;
    -webkit-transform: translateY(3px);
    -khtml-transform: translateY(3px);
    -moz-transform: translateY(3px);
    -ms-transform: translateY(3px);
    -o-transform: translateY(3px);
    transform: translateY(3px); }
  33.3% {
    -webkit-transform: translateY(-2px);
    -khtml-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
    transform: translateY(-2px); }
  49.95% {
    color: #006d9d;
    -webkit-transform: translateY(1px);
    -khtml-transform: translateY(1px);
    -moz-transform: translateY(1px);
    -ms-transform: translateY(1px);
    -o-transform: translateY(1px);
    transform: translateY(1px); }
  66.6% {
    color: #006b24;
    -webkit-transform: translateY(0px);
    -khtml-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px); }
  83.25% {
    -webkit-transform: translateY(0px);
    -khtml-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px); }
  100% {
    -webkit-transform: translateY(0);
    -khtml-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@keyframes bounce {
  16.65% {
    color: #006b24;
    -webkit-transform: translateY(3px);
    -khtml-transform: translateY(3px);
    -moz-transform: translateY(3px);
    -ms-transform: translateY(3px);
    -o-transform: translateY(3px);
    transform: translateY(3px); }
  33.3% {
    -webkit-transform: translateY(-2px);
    -khtml-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
    transform: translateY(-2px); }
  49.95% {
    color: #006d9d;
    -webkit-transform: translateY(1px);
    -khtml-transform: translateY(1px);
    -moz-transform: translateY(1px);
    -ms-transform: translateY(1px);
    -o-transform: translateY(1px);
    transform: translateY(1px); }
  66.6% {
    color: #006b24;
    -webkit-transform: translateY(0px);
    -khtml-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px); }
  83.25% {
    -webkit-transform: translateY(0px);
    -khtml-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px); }
  100% {
    -webkit-transform: translateY(0);
    -khtml-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

html {
  -webkit-box-sizing: border-box;
  -khtml-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box; }

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -khtml-box-sizing: inherit;
  -moz-box-sizing: inherit;
  -ms-box-sizing: inherit;
  -o-box-sizing: inherit;
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #ffffff;
  color: #5b6268;
  font-family: Helvetica, sans-serif;
  font-size: 14px;
  line-height: 150%; }

ul {
  margin: 0;
  padding-left: 20px; }

a, a:visited {
  color: inherit;
  text-decoration: none;
  cursor: pointer; }

a:hover {
  color: #000; }

.contain {
  max-width: 1200px;
  margin: auto;
  position: relative; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  line-height: 1em; }

h1 {
  font-size: 45px;
  margin: 30px 0; }

h2 {
  font-size: 40px;
  line-height: 1.2em;
  text-align: center; }

h3 {
  font-size: 20px; }

h4 {
  font-size: 18px; }

p {
  margin: 0;
  margin-bottom: 1em; }

*::-webkit-input-placeholder {
  color: #acacac !important;
  font-weight: 300;
  font-family: Helvetica, sans-serif; }

*:-moz-placeholder {
  color: #acacac !important;
  font-weight: 300;
  font-family: Helvetica, sans-serif; }

*::-moz-placeholder {
  color: #acacac !important;
  font-weight: 300;
  font-family: Helvetica, sans-serif; }

*:-ms-input-placeholder {
  color: #acacac !important;
  font-weight: 300;
  font-family: Helvetica, sans-serif; }

* .placeholder {
  color: #acacac !important;
  font-weight: 300;
  font-family: Helvetica, sans-serif; }

/*-------------------------
        Header
---------------------------*/
header {
  position: fixed;
  top: 0;
  right: 20px;
  z-index: 999;
  display: none; }
  header.fixed {
    display: block; }
  header .main_menu {
    background: #fff;
    float: right;
    -webkit-border-radius: 0px 0px 5px 5px;
    -khtml-border-radius: 0px 0px 5px 5px;
    -moz-border-radius: 0px 0px 5px 5px;
    -ms-border-radius: 0px 0px 5px 5px;
    -o-border-radius: 0px 0px 5px 5px;
    border-radius: 0px 0px 5px 5px;
    text-transform: uppercase;
    font-size: 17px;
    -webkit-box-shadow: 0px 0px 5px grey;
    -khtml-box-shadow: 0px 0px 5px grey;
    -moz-box-shadow: 0px 0px 5px grey;
    -ms-box-shadow: 0px 0px 5px grey;
    -o-box-shadow: 0px 0px 5px grey;
    box-shadow: 0px 0px 5px grey; }
    header .main_menu .letter {
      display: inline-block;
      margin-left: -5px; }
    header .main_menu:hover {
      color: #5b6268; }
      header .main_menu:hover .letter:nth-child(1) {
        -webkit-animation: bounce 1.5s infinite ease-out forwards;
        -khtml-animation: bounce 1.5s infinite ease-out forwards;
        -moz-animation: bounce 1.5s infinite ease-out forwards;
        -ms-animation: bounce 1.5s infinite ease-out forwards;
        -o-animation: bounce 1.5s infinite ease-out forwards;
        animation: bounce 1.5s infinite ease-out forwards;
        -webkit-animation-delay: 0.1s;
        -khtml-animation-delay: 0.1s;
        -moz-animation-delay: 0.1s;
        -ms-animation-delay: 0.1s;
        -o-animation-delay: 0.1s;
        animation-delay: 0.1s; }
      header .main_menu:hover .letter:nth-child(2) {
        -webkit-animation: bounce 1.5s infinite ease-out forwards;
        -khtml-animation: bounce 1.5s infinite ease-out forwards;
        -moz-animation: bounce 1.5s infinite ease-out forwards;
        -ms-animation: bounce 1.5s infinite ease-out forwards;
        -o-animation: bounce 1.5s infinite ease-out forwards;
        animation: bounce 1.5s infinite ease-out forwards;
        -webkit-animation-delay: 0.2s;
        -khtml-animation-delay: 0.2s;
        -moz-animation-delay: 0.2s;
        -ms-animation-delay: 0.2s;
        -o-animation-delay: 0.2s;
        animation-delay: 0.2s; }
      header .main_menu:hover .letter:nth-child(3) {
        -webkit-animation: bounce 1.5s infinite ease-out forwards;
        -khtml-animation: bounce 1.5s infinite ease-out forwards;
        -moz-animation: bounce 1.5s infinite ease-out forwards;
        -ms-animation: bounce 1.5s infinite ease-out forwards;
        -o-animation: bounce 1.5s infinite ease-out forwards;
        animation: bounce 1.5s infinite ease-out forwards;
        -webkit-animation-delay: 0.3s;
        -khtml-animation-delay: 0.3s;
        -moz-animation-delay: 0.3s;
        -ms-animation-delay: 0.3s;
        -o-animation-delay: 0.3s;
        animation-delay: 0.3s; }
      header .main_menu:hover .letter:nth-child(4) {
        -webkit-animation: bounce 1.5s infinite ease-out forwards;
        -khtml-animation: bounce 1.5s infinite ease-out forwards;
        -moz-animation: bounce 1.5s infinite ease-out forwards;
        -ms-animation: bounce 1.5s infinite ease-out forwards;
        -o-animation: bounce 1.5s infinite ease-out forwards;
        animation: bounce 1.5s infinite ease-out forwards;
        -webkit-animation-delay: 0.4s;
        -khtml-animation-delay: 0.4s;
        -moz-animation-delay: 0.4s;
        -ms-animation-delay: 0.4s;
        -o-animation-delay: 0.4s;
        animation-delay: 0.4s; }
      header .main_menu:hover .letter:nth-child(5) {
        -webkit-animation: bounce 1.5s infinite ease-out forwards;
        -khtml-animation: bounce 1.5s infinite ease-out forwards;
        -moz-animation: bounce 1.5s infinite ease-out forwards;
        -ms-animation: bounce 1.5s infinite ease-out forwards;
        -o-animation: bounce 1.5s infinite ease-out forwards;
        animation: bounce 1.5s infinite ease-out forwards;
        -webkit-animation-delay: 0.5s;
        -khtml-animation-delay: 0.5s;
        -moz-animation-delay: 0.5s;
        -ms-animation-delay: 0.5s;
        -o-animation-delay: 0.5s;
        animation-delay: 0.5s; }
      header .main_menu:hover .letter:nth-child(6) {
        -webkit-animation: bounce 1.5s infinite ease-out forwards;
        -khtml-animation: bounce 1.5s infinite ease-out forwards;
        -moz-animation: bounce 1.5s infinite ease-out forwards;
        -ms-animation: bounce 1.5s infinite ease-out forwards;
        -o-animation: bounce 1.5s infinite ease-out forwards;
        animation: bounce 1.5s infinite ease-out forwards;
        -webkit-animation-delay: 0.6s;
        -khtml-animation-delay: 0.6s;
        -moz-animation-delay: 0.6s;
        -ms-animation-delay: 0.6s;
        -o-animation-delay: 0.6s;
        animation-delay: 0.6s; }
      header .main_menu:hover .letter:nth-child(7) {
        -webkit-animation: bounce 1.5s infinite ease-out forwards;
        -khtml-animation: bounce 1.5s infinite ease-out forwards;
        -moz-animation: bounce 1.5s infinite ease-out forwards;
        -ms-animation: bounce 1.5s infinite ease-out forwards;
        -o-animation: bounce 1.5s infinite ease-out forwards;
        animation: bounce 1.5s infinite ease-out forwards;
        -webkit-animation-delay: 0.7s;
        -khtml-animation-delay: 0.7s;
        -moz-animation-delay: 0.7s;
        -ms-animation-delay: 0.7s;
        -o-animation-delay: 0.7s;
        animation-delay: 0.7s; }
      header .main_menu:hover .letter:nth-child(8) {
        -webkit-animation: bounce 1.5s infinite ease-out forwards;
        -khtml-animation: bounce 1.5s infinite ease-out forwards;
        -moz-animation: bounce 1.5s infinite ease-out forwards;
        -ms-animation: bounce 1.5s infinite ease-out forwards;
        -o-animation: bounce 1.5s infinite ease-out forwards;
        animation: bounce 1.5s infinite ease-out forwards;
        -webkit-animation-delay: 0.8s;
        -khtml-animation-delay: 0.8s;
        -moz-animation-delay: 0.8s;
        -ms-animation-delay: 0.8s;
        -o-animation-delay: 0.8s;
        animation-delay: 0.8s; }
    header .main_menu a {
      display: block;
      padding: 10px 20px; }
      header .main_menu a:hover {
        color: inherit; }

/*-------------------------
        Content
---------------------------*/
.loading_hero {
  position: fixed;
  text-align: center;
  background: #808080;
  z-index: 9999; }
  .loading_hero.done {
    -webkit-transition-duration: 1s;
    -moz-transition-duration: 1s;
    -ms-transition-duration: 1s;
    -o-transition-duration: 1s;
    transition-duration: 1s;
    -webkit-transition-delay: 1s;
    -khtml-transition-delay: 1s;
    -moz-transition-delay: 1s;
    -ms-transition-delay: 1s;
    -o-transition-delay: 1s;
    transition-delay: 1s;
    filter: alpha(opacity=0);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
    visibility: hidden; }
  .loading_hero .animated_loading {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -khtml-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 5;
    width: 100%; }

.main_hero {
  text-align: center;
  position: relative; }
  .main_hero::after {
    content: '';
    background: #000;
    filter: alpha(opacity=70);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
    -moz-opacity: 0.7;
    -khtml-opacity: 0.7;
    opacity: 0.7;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .main_hero .animated_hero_title_wrap {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -khtml-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    z-index: 6; }
    .main_hero .animated_hero_title_wrap .animated_hero_title {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -khtml-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 100%;
      z-index: 6; }
  .main_hero .arrow_more {
    position: absolute;
    bottom: 20px;
    z-index: 10;
    bottom: 5%;
    left: 0;
    right: 0;
    text-align: center;
    color: #fff;
    font-size: 40px;
    cursor: pointer;
    -webkit-animation: Floating 2s infinite ease-in-out;
    -khtml-animation: Floating 2s infinite ease-in-out;
    -moz-animation: Floating 2s infinite ease-in-out;
    -ms-animation: Floating 2s infinite ease-in-out;
    -o-animation: Floating 2s infinite ease-in-out;
    animation: Floating 2s infinite ease-in-out; }

.full_height {
  width: 100%;
  height: 100vh; }

.section {
  padding: 40px 0; }
  .section.section_projects {
    position: relative; }
    .section.section_projects::before, .section.section_projects::after {
      z-index: -2;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute; }
    .section.section_projects::after {
      background-image: url(/img/sayagata.png);
      z-index: -1;
      filter: alpha(opacity=50);
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
      -moz-opacity: 0.5;
      -khtml-opacity: 0.5;
      opacity: 0.5; }
  .section.section_skills {
    background-image: url(/img/footer_lodyas.png);
    position: relative;
    padding: 0;
    /* &.mouseDown * {
            cursor: url(/img/hand_move.png), auto !important;
            opacity: 0.5;
        }*/ }
    .section.section_skills .header_title {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      right: 0; }
  .section.section_education {
    background-image: url(/img/pattern_bg2.png); }
  .section.section_exp {
    background-image: url(/img/mochaGrunge.png); }

.header_title {
  text-align: center; }

.grid_3 {
  float: left;
  width: 33.33%; }

/*-------------------------
        Effect
---------------------------*/
.skills_effect_wrap #change_style {
  position: absolute;
  z-index: 10;
  top: 210px;
  left: 0;
  right: 0;
  text-align: center; }
  .skills_effect_wrap #change_style button {
    color: rgba(255, 255, 255, 0.99);
    background: transparent;
    outline: 1px solid rgba(255, 255, 255, 0.99);
    border: 0px;
    padding: 5px 10px;
    cursor: pointer; }
  .skills_effect_wrap #change_style button:hover {
    background-color: rgba(255, 255, 255, 0.5); }
  .skills_effect_wrap #change_style button.active {
    /*color: #000000;
            background-color: rgba(0, 255, 255, 0.75);*/
    /*color: rgba(80, 80, 80, 0.99);
            background-color: rgba(255, 255, 255, 0.99);*/
    background-color: rgba(255, 255, 255, 0.5); }

.skills_effect_wrap:not(.alternate_skill) #skills_effect {
  width: 100%;
  height: 850px; }
  .skills_effect_wrap:not(.alternate_skill) #skills_effect * {
    cursor: url(/img/hand_move.png) 20 15, move !important; }

.skills_effect_wrap .element {
  width: 550px;
  height: 300px;
  box-shadow: 0px 0px 12px rgba(255, 255, 255, 0.5);
  text-align: center;
  cursor: default;
  background: rgba(1, 72, 69, 0.9); }
  .skills_effect_wrap .element:hover {
    box-shadow: 0px 0px 12px rgba(255, 255, 255, 0.75);
    border: 1px solid rgba(255, 255, 255, 0.75); }
  .skills_effect_wrap .element .number {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 12px;
    color: rgba(127, 255, 255, 0.75);
    display: none; }
  .skills_effect_wrap .element .symbol {
    position: absolute;
    top: 70px;
    left: 0px;
    right: 0px;
    font-size: 100px;
    line-height: 100px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.75); }
  .skills_effect_wrap .element .details {
    position: absolute;
    bottom: 50px;
    left: 0px;
    right: 0px;
    font-size: 60px;
    color: rgba(230, 150, 0, 0.75); }
    .skills_effect_wrap .element .details .details_2 {
      display: none; }

.skills_effect_wrap.alternate_skill {
  padding-top: 190px;
  padding-bottom: 60px;
  text-align: center; }
  .skills_effect_wrap.alternate_skill #skills_effect {
    height: auto; }
  .skills_effect_wrap.alternate_skill .alternate_el_wrap {
    color: #fff;
    text-align: center;
    display: inline-block;
    vertical-align: top;
    margin: 5px;
    perspective: 1000px;
    background-color: transparent; }
    .skills_effect_wrap.alternate_skill .alternate_el_wrap:hover .alternate_el {
      transform: rotateY(360deg); }
    .skills_effect_wrap.alternate_skill .alternate_el_wrap .alternate_el {
      position: relative;
      width: 155px;
      text-align: center;
      transition: transform 0.8s;
      transform-style: preserve-3d;
      background: rgba(1, 72, 69, 0.9);
      border: 1px solid rgba(255, 255, 255, 0.75);
      padding: 10px; }
      .skills_effect_wrap.alternate_skill .alternate_el_wrap .alternate_el .name {
        font-size: 22px; }
      .skills_effect_wrap.alternate_skill .alternate_el_wrap .alternate_el .rating {
        color: rgba(230, 150, 0, 0.75);
        font-size: 16px;
        margin-top: 6px; }
        .skills_effect_wrap.alternate_skill .alternate_el_wrap .alternate_el .rating i {
          margin: 0 1px; }

.exp_wrap {
  *zoom: 1;
  margin-bottom: 60px;
  padding: 0 20px;
  /*&:nth-child(odd) .img{
        float:right;
    }*/ }
  .exp_wrap:before, .exp_wrap:after {
    content: " ";
    display: table; }
  .exp_wrap:after {
    clear: both; }
  .exp_wrap .img {
    float: left;
    width: 100%;
    max-width: 300px;
    height: 300px;
    background-size: 80%;
    background-position: top center;
    background-repeat: no-repeat; }
  .exp_wrap .content_wrap {
    float: left;
    width: calc(100% - 350px);
    margin-left: 50px;
    background: #fff;
    padding: 10%;
    position: relative; }
    .exp_wrap .content_wrap .content h4 {
      font-size: 25px; }
      .exp_wrap .content_wrap .content h4:nth-child(1) {
        margin-top: 0; }
    .exp_wrap .content_wrap .content ul:nth-child(6) {
      *zoom: 1;
      list-style: none;
      padding: 0; }
      .exp_wrap .content_wrap .content ul:nth-child(6):before, .exp_wrap .content_wrap .content ul:nth-child(6):after {
        content: " ";
        display: table; }
      .exp_wrap .content_wrap .content ul:nth-child(6):after {
        clear: both; }
      .exp_wrap .content_wrap .content ul:nth-child(6) li {
        display: block;
        float: left;
        margin: 0 5px 10px 5px;
        padding: 5px 10px;
        background: #b2d3f4; }
    .exp_wrap .content_wrap .content .details {
      text-align: right;
      position: absolute;
      top: 0;
      right: 0;
      background: #454545;
      color: #fff;
      padding: 20px;
      -webkit-border-radius: 0 0 0 5px;
      -khtml-border-radius: 0 0 0 5px;
      -moz-border-radius: 0 0 0 5px;
      -ms-border-radius: 0 0 0 5px;
      -o-border-radius: 0 0 0 5px;
      border-radius: 0 0 0 5px; }

.form_wrap {
  margin-bottom: 70px;
  *zoom: 1; }
  .form_wrap:before, .form_wrap:after {
    content: " ";
    display: table; }
  .form_wrap:after {
    clear: both; }
  .form_wrap .input--kozakura input,
  .form_wrap .input--kozakura textarea {
    color: #5b6268; }
  .form_wrap .input {
    float: left;
    width: calc(50% - 40px);
    margin: 0 20px; }
    .form_wrap .input.error .graphic {
      fill: #ff6161; }
  .form_wrap .graphic {
    height: 80px; }
  .form_wrap textarea {
    height: 230px;
    resize: none; }
  .form_wrap .textarea {
    width: calc(100% - 40px);
    height: 300px; }
    .form_wrap .textarea .graphic--kozakura {
      height: 300px; }
    .form_wrap .textarea .input__label--kozakura {
      top: 20px; }
    .form_wrap .textarea .input__field--kozakura {
      margin-top: 60px; }
  .form_wrap .submit {
    float: right;
    margin-right: 20px;
    margin-top: 30px;
    /*padding:10px 30px;*/
    cursor: pointer;
    /*

        display:block;

        border:none;

        text-transform:uppercase;
        font-size:18px;
        */
    /*&.progress-button.state-error .content{
            background: #ff6161 !important;
        }*/ }
  .form_wrap .success {
    display: none;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -khtml-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: center;
    font-size: 40px;
    line-height: 40px;
    padding: 0 20px; }

@media (max-width: 1000px) {
  h2.text_hover_effect {
    font-size: 6em; }
  .grid_3 {
    width: 50%; }
  .exp_wrap .img {
    margin: auto;
    float: none;
    background-position: center; }
  .exp_wrap .content_wrap {
    margin: auto;
    float: none;
    width: 100%;
    padding-top: 15%; }
    .exp_wrap .content_wrap .content .details {
      width: 100%;
      left: 0;
      padding: 10px;
      -webkit-border-radius: 0;
      -khtml-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      -o-border-radius: 0;
      border-radius: 0; }
      .exp_wrap .content_wrap .content .details .from_to {
        float: left; } }

@media (max-width: 750px) {
  h2.text_hover_effect {
    font-size: 4em; }
  .section.section_skills .header_title {
    top: 50px; }
  .form_wrap .input {
    width: calc(100% - 40px); }
  .form_wrap .submit {
    width: calc(100%  - 40px); }
  .form_wrap .textarea {
    margin-top: -30px; } }

@media (max-width: 700px) {
  .row_key {
    height: 300px !important; }
    .row_key .year {
      margin-right: 0 !important;
      margin-left: 45px !important;
      top: 30px !important; }
    .row_key .details_wrap {
      margin-right: 0 !important;
      margin-left: 0 !important; } }

@media (max-width: 600px) {
  .grid_3 {
    width: 100%; } }

@media (max-width: 500px) {
  h2.text_hover_effect {
    font-size: 2em; } }
