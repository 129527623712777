.clearfix {
  *zoom: 1; }
  .clearfix:before, .clearfix:after {
    content: " ";
    display: table; }
  .clearfix:after {
    clear: both; }

.timeline_wrap {
  margin-bottom: 80px; }
  .timeline_wrap .row_key {
    position: relative; }
    .timeline_wrap .row_key:nth-child(even) .year {
      margin-left: -100px; }
    .timeline_wrap .row_key:nth-child(even) .details_wrap {
      margin-left: 200px; }
    .timeline_wrap .row_key:nth-child(odd) .year {
      margin-left: 100px; }
    .timeline_wrap .row_key:nth-child(odd) .details_wrap {
      margin-left: -200px; }
    .timeline_wrap .row_key > * {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      -khtml-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
    .timeline_wrap .row_key .bar {
      height: 100%;
      width: 10px; }
    .timeline_wrap .row_key .year {
      font-size: 25px;
      font-weight: bold; }
    .timeline_wrap .row_key .details_wrap {
      width: 250px;
      height: 250px;
      text-align: center;
      position: relative; }
      .timeline_wrap .row_key .details_wrap:hover .circle {
        width: 250px;
        height: 200px;
        border-radius: 30px; }
      .timeline_wrap .row_key .details_wrap:hover .icon {
        font-size: 40px !important;
        margin: 15px 0;
        -webkit-transition-delay: 0.5s !important;
        -khtml-transition-delay: 0.5s !important;
        -moz-transition-delay: 0.5s !important;
        -ms-transition-delay: 0.5s !important;
        -o-transition-delay: 0.5s !important;
        transition-delay: 0.5s !important; }
      .timeline_wrap .row_key .details_wrap:hover .details {
        width: 230px; }
        .timeline_wrap .row_key .details_wrap:hover .details .full_details {
          /*font-size:14px !important;*/
          line-height: 18px !important;
          filter: alpha(opacity=100);
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
          -moz-opacity: 1;
          -khtml-opacity: 1;
          opacity: 1;
          -webkit-transition-delay: 0.8s !important;
          -khtml-transition-delay: 0.8s !important;
          -moz-transition-delay: 0.8s !important;
          -ms-transition-delay: 0.8s !important;
          -o-transition-delay: 0.8s !important;
          transition-delay: 0.8s !important; }
      .timeline_wrap .row_key .details_wrap .details {
        width: 130px;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -khtml-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        color: #fff;
        -webkit-transition: all 0.5s;
        -khtml-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -ms-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
        /*margin-top:0px;*/ }
        .timeline_wrap .row_key .details_wrap .details .main_title {
          font-size: 25px;
          font-weight: bold; }
        .timeline_wrap .row_key .details_wrap .details .icon {
          -webkit-transition: all 0.5s;
          -khtml-transition: all 0.5s;
          -moz-transition: all 0.5s;
          -ms-transition: all 0.5s;
          -o-transition: all 0.5s;
          transition: all 0.5s;
          -webkit-transition-delay: 0s;
          -khtml-transition-delay: 0s;
          -moz-transition-delay: 0s;
          -ms-transition-delay: 0s;
          -o-transition-delay: 0s;
          transition-delay: 0s;
          font-size: 0px; }
        .timeline_wrap .row_key .details_wrap .details .full_details {
          font-size: 14px;
          line-height: 0;
          filter: alpha(opacity=0);
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
          -moz-opacity: 0;
          -khtml-opacity: 0;
          opacity: 0;
          -webkit-transition: all 0.5s;
          -khtml-transition: all 0.5s;
          -moz-transition: all 0.5s;
          -ms-transition: all 0.5s;
          -o-transition: all 0.5s;
          transition: all 0.5s;
          -webkit-transition-delay: 0s;
          -khtml-transition-delay: 0s;
          -moz-transition-delay: 0s;
          -ms-transition-delay: 0s;
          -o-transition-delay: 0s;
          transition-delay: 0s; }
      .timeline_wrap .row_key .details_wrap .circle {
        /*-webkit-transition-duration: .5s;
                transition-duration: .5s;*/
        -webkit-transition: all 0.5s;
        -khtml-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -ms-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
        width: 150px;
        height: 150px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -khtml-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        border-radius: 50%; }
